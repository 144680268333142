<template>
  <div class="warp">
    <van-sticky>
      <div>
        <van-search
          v-model="RegionFrom.title"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
    </van-sticky>
    <div class="wktime">
      <!-- 全部 -->
      <van-list
      :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoad" 
        :immediate-check="false"
      >
        <div v-for="item in list" :key="item" class="listRid">
          <div class="listRidtitle">{{ item.Title }}</div>
          <div class="listRidname">
            <span>联系人: {{ item.Linkmen }}</span>
            <span>联系电话: {{ item.Linkway }}</span>
          </div>
          <div class="listRidname">
            <span>物业名称: {{ item.Property }}</span>
          </div>
          <div class="listRidname">
            <span>物业联系人: {{ item.PropertyLead }}</span>
          </div>
          <div class="listRidname">
            <span>物业联系电话: {{ item.PropertyTel }}</span>
          </div>
          <div class="listRidname">地址: {{ item.Address }}</div>
        </div>
      </van-list>
    </div>
    <!-- <div style="text-align: center">
      <van-loading type="spinner" v-if="loadingShow" />
    </div> -->
    <van-empty description="暂未数据" v-if="!loadingShow" />
  </div>
</template>

<script>
import { WeGetRegionPage } from "@/api/index";

export default {
  data() {
    return {
      RegionFrom: {
        title: "",
        page:1,
        limit:10,
      }, //小区列表传参
      list: [], //小区列表
      loadingShow: false,
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  created() {
    this.RegionPage();
  },
  methods: {
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.RegionFrom.page = this.RegionFrom.page + 1;
      this.RegionPage();
    },
    // 搜索
    onSearch(row) {
      this.RegionFrom.page = 1;
      this.list = [];
      console.log(row);
      this.RegionFrom.title = row;
      this.RegionPage();
    },
    onClickLeft() {
      window.history.go(-1);
    },
    // 获取小区列表
    RegionPage() {
      // this.list = [];
      // this.loadingShow = true;
      WeGetRegionPage(this.RegionFrom).then((res) => {
        let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束
          this.loading = false;

          if (res.data.code == 0) {
            if (rows == null || rows.length === 0) {
              // 加载结束
              console.log("结束");
              this.finished = true;
              this.hidden = true
              return;
            }
            // 将新数据与老数据进行合并
            this.list = this.list.concat(rows);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list.length >= res.data.count) {
              this.finished = true;
              this.hidden = true
            }
          }
      });
    },
  },
};
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}
.listRid {
  background: rgb(238, 236, 236);
  border-radius: 15px;
  box-shadow: 1px 1px 1px #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>